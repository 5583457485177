import $ from 'jquery';

$(() => {

	// Toggle mobile menu visibility when button clicked
	$('#js-mobile-menu-button').on('click', event => {
		const $mobileMenuButton = $(event.currentTarget);

		$($mobileMenuButton).toggleClass('is-menu-visible');
		$('#js-mobile-menu').toggleClass('is-visible');
	});

});
