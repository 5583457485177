import $ from 'jquery';

$(() => {
	'use strict';

	const element = document.querySelector('.js-header');

	if (!$(element).length) {
		return;
	}

	let timeout;

	window.addEventListener('scroll', function() {

		// If timer is null, reset it to 66ms and run your functions.
		// Otherwise, wait until timer is cleared
		if (!timeout) {
			timeout = setTimeout(function() {

				// Reset timeout
				timeout = null;

				// Run scroll function
				let	wScrollBefore = 0;
				let wScrollCurrent	= window.pageYOffset;
				let wScrollDiff = wScrollBefore - wScrollCurrent;

				if (wScrollCurrent <= 0) {
					// scrolled to the very top; element sticks to the top
					element.classList.remove('o-header--scrolled');
				} else if (wScrollDiff < 0) {
					// scrolled down
					element.classList.add('o-header--scrolled');
				}

				wScrollBefore = wScrollCurrent;
			}, 66);
		}
	}, false);
});
