import $ from 'jquery';

import disableScroll from '../utilities/disableScroll';
import enableScroll from '../utilities/enableScroll';

$(() => {

	// Toggle menu visibility on button click
	$('.js-menu-button').on('click', event => {
		$(event.currentTarget).toggleClass('is-menu-open');

		const $nav = $('#js-menu');

		// Hide menu
		if ($nav.hasClass('is-active')) {
			$nav.removeClass('is-active');

			setTimeout( () => {
				$($nav).hide();
			}, 500);

			enableScroll();

		// Show menu
		} else {
			setTimeout( () => {
				$nav.addClass('is-active');
			}, 0);

			$($nav).css('display', 'flex');

			disableScroll();
		}
	});

});
