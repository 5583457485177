import $ from 'jquery';
import Flickity from 'flickity-imagesloaded';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

$(() => {
	if (!$('.js-marquee').length) {
		return;
	}

	$('.js-marquee').each( (index, marquee) => {
		// Tablet media query
		const isSmallMQ = window.matchMedia('(max-width: 991px)');

		// Value controlling speed
		let tickerSpeed;

		if (isSmallMQ.matches) {
			tickerSpeed = .5;
		} else {
			tickerSpeed = 1;
		}

		let isPaused = true;

		// Create Flickity
		const flickity = new Flickity(marquee, {
			autoPlay: false,
			prevNextButtons: false,
			pageDots: false,
			draggable: true,
			wrapAround: true,
			imagesLoaded: true,
			selectedAttraction: 0.015,
			friction: 0.25,
			imagesLoaded: true
		});

		flickity.x = 0;

		// Functions
		function update() {
			if (isPaused) return;
			if (flickity.slides) {
				flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
				flickity.selectedIndex = flickity.dragEndRestingSelect();
				flickity.updateSelectedSlide();
				flickity.settle(flickity.x);
			}
			window.requestAnimationFrame(update);
		}

		function pause() {
			isPaused = true;
		}

		function play() {
			if (isPaused) {
				isPaused = false;
				window.requestAnimationFrame(update);
			}
		}

		// Add Event Listeners
		marquee.addEventListener('mouseenter', pause, false);
		marquee.addEventListener('focusin', pause, false);
		marquee.addEventListener('mouseleave', play, false);
		marquee.addEventListener('focusout', play, false);

		flickity.on('dragStart', () => {
			isPaused = true;
		});

		// Play when in viewport
		ScrollTrigger.create({
			trigger: marquee,
			start: 'top bottom',
			end: 'bottom top',
			onEnter: play,
			onLeave: pause,
			onEnterBack: play,
			onLeaveBack: pause,
		});

		update();
	});
});
