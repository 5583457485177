import $ from 'jquery';
import { gsap } from 'gsap/all';
import { DrawSVGPlugin } from '../../lib/gsap-extras/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

const intro = () => {
	const circleShapes = '#inner-circle, #outer-ring, #inner-ring';

	let xPercent = null;

	if (window.matchMedia('(min-width: 1201px)').matches) {
		xPercent = '63%';
	} else if (window.matchMedia('(min-width: 993px) and (max-width: 1200px)').matches) {
		xPercent = '43%';
	}

	console.log(xPercent);

	const timeline = gsap.timeline({
		paused:true,
		scrollTrigger: {
			trigger: '.js-infographic',
			start: 'top center'
		}
	});

	timeline
		.set($('.js-infographic svg'), {
			visibility: 'visible',
			onComplete: () => {
				$('.js-infographic svg').attr('animation', 'running');
				$('.js-infographic svg').css('pointer-events', 'none');
			}
		})
		.set(circleShapes, {
			rotate: -90,
			transformOrigin: 'center center'
		})
		.from(circleShapes, {
			drawSVG: '0%',
			duration: 1.5,
			stagger: 0.5,
			ease: 'power3.out'
		})
		.from($('#circle-shadow'), {
			opacity: 0,
			duration: 1,
			ease: 'power4.in'
		}, '-=1')
		.add('background')
		.from($('#slice-right'), {
			opacity: 0,
			duration: 1.5,
			ease: 'power4.in'
		}, '-=1')
		.from($('.c-infographic__section--slice'), {
			opacity: 0,
			duration: 1,
			stagger: .1
		}, 'background')
		.add('numbers')
		.from($('.c-infographic__section--number'), {
			opacity: 0,
			duration: .75,
			stagger: .1
		}, 'numbers-=1.5')
		.from($('.js-infographic .point'), {
			opacity: 0,
			duration: .75
		}, 'numbers-=1.5')
		.from($('.c-infographic__section--heading'), {
			scale: 0,
			transformOrigin: 'center center',
			duration: .75,
			stagger: .1,
			ease: 'back.out(1)',
			onComplete: () => {
				$('.js-infographic svg').attr('animation', 'complete');
				$('.js-infographic svg, .js-infographic-steps').css('pointer-events', 'initial');
				$('.c-infographic__section--number[data-section="1"], .c-infographic__section--outer[data-section="1"], .c-infographic__section--heading[data-section="1"]').addClass('active');
			}
		}, 'numbers-=1.5')
		.from($('.js-infographic svg'), {
			x: xPercent,
			duration: .75,
			ease: 'back.inOut(1)'
		}, '-=0.5')
		.from($('.js-infographic-copy'), {
			opacity: 0,
			duration: .75
		}, '-=.25');

	return timeline;
};

export default intro;
