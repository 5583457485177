import $ from 'jquery';

// Enable body scrolling
const enableScroll = () => {
	const scrollTop = parseInt($('html').css('top'));
	$('html').removeClass('u-disable-scrolling');
	$('html, body').scrollTop(-scrollTop);
};

export default enableScroll;
