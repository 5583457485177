import $ from 'jquery';

$(() => {
	if (!$('.js-services-banner-tab').length) {
		return;
	}

	$('.js-services-banner-tab').on('mouseenter', (event) => {
		const $this = $(event.currentTarget);
		const tab = $($this).attr('data-service');

		// Change the active tab
		$('.c-services-banner__button--current').removeClass('c-services-banner__button--current').addClass('c-services-banner__button--not-current');
		$($this).removeClass('c-services-banner__button--not-current').addClass('c-services-banner__button--current');

		// Show the relevant content
		$('.js-services-banner-content.c-services-banner-content__wrapper--current').removeClass('c-services-banner-content__wrapper--current').addClass('c-services-banner-content__wrapper--not-current');
		$('.js-services-banner-content[data-service="' + tab + '"').removeClass('c-services-banner-content__wrapper--not-current').addClass('c-services-banner-content__wrapper--current');
	});
});
