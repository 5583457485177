import $ from 'jquery';
import { gsap } from 'gsap/all';
import MorphSVGPlugin from '../lib/gsap-extras/MorphSVGPlugin';

gsap.registerPlugin(MorphSVGPlugin);

// Open / close mobile accordion step
$('.js-cross-arrow').on('click', (event) => {
	const $this = event.currentTarget;
	const $step = $($this).closest('.js-infographic-step');

	if (!$($step).hasClass('active')) {
		gsap.to( $($this).find('#arrow'), {
			morphSVG: '#cross'
		});
	} else {
		gsap.to( $($this).find('#arrow'), {
			morphSVG: '#arrow'
		});
	}

	!$($step).hasClass('active') ? $($step).addClass('active') : $($step).removeClass('active');
});

