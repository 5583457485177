import $ from 'jquery';
import { gsap } from 'gsap/all';
import { DrawSVGPlugin } from '../lib/gsap-extras/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

$(() => {

	if (!$('.js-draw-svg').length){
		return;
	}

	function draw(svg) {
		const timeline = gsap.timeline({ paused: true });

		const path = $(svg).find('path');

		timeline
			.set(svg, {
				visibility: 'visible',
			})
			.from(path, {
				drawSVG: '0%',
				duration: 1.5,
				stagger: 0.2
			})
			.from(path, {
				fillOpacity: 0,
				duration: 1
			}, '-=.5')
			.to(path, {
				strokeWidth: 0,
				duration: 1
			}, '-=.5');

		return timeline;
	}

	$('.js-draw-svg').each( (index, svg) => {

		ScrollTrigger.create({
			trigger: svg,
			start: 'top bottom',
			end: 'bottom top',
			onEnter: () => {
				draw(svg).play();
			},
			onLeaveBack: self => self.disable()
		});
	});

});
