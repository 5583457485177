import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

$(() => {

	if (!$('.js-circle').length) {
		return;
	}

	// Get all the elements to animate
	let sections = document.querySelectorAll('.js-circle-section');

	// Scale animation, pass transform scale value
	function fade(circle, text, direction) {
		gsap.to(circle, {
			duration: 1,
			scale: direction === 'forward' ? 1 : 0
		}),
		gsap.to(text, {
			duration: 1,
			scale: direction === 'forward' ? 1 : .25
		}),
		gsap.to(text, {
			duration: 1,
			ease: direction === 'forward' ? 'power2.in' : 'power4.out',
			opacity: direction === 'forward' ? 1 : 0
		});
	}

	// Scrolltrigger for animation
	sections.forEach((section) => {
		const circle = section.querySelector('.js-circle');
		const text = section.querySelector('.js-circle-text');

		ScrollTrigger.create({
			trigger: section,
			start: 'center +=800',
			end: '+=500',
			scrub: 2,
			onEnterBack: () => {
				fade(circle, text, 'forward');
			},
			onEnter: () => {
				fade(circle, text, 'forward');
			},
			onLeave: () => {
				fade(circle, text, 'reverse');
			},
			onLeaveBack: () => {
				fade(circle, text, 'reverse');
			}
		});
	});

});
