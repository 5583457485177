import $ from 'jquery';

$(() => {
	// Define small device query
	let isSmallMQ = window.matchMedia('(max-width: 1023px)');

	if (!isSmallMQ.matches) {
		$('li.c-menu__item.menu-item-has-children').hover(
			() => {
				$(event.currentTarget).addClass('active');
			},
			() => {
				$(event.currentTarget).removeClass('active');
			}
		);
	}
});
