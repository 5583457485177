import $ from 'jquery';

// Disable body scrolling, maintaining position and keeping scrollbar
const disableScroll = () => {
	if($(document).height() > $(window).height()) {
		const scrollTop = $('html').scrollTop() || $('body').scrollTop();
		$('html').addClass('u-disable-scrolling').css('top', -scrollTop);
	}
};

export default disableScroll;
