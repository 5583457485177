import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

$(() => {

	// Tablet media query
	const isSmallMQ = window.matchMedia('(max-width: 991px)');

	// Get all parallax sections on the page
	const parallaxSection = document.querySelectorAll('.js-parallax-section');

	// If no parallax sections, return
	if (!parallaxSection.length) {
		return;
	}

	function parallaxAnimation(section, image) {
		// GSAP scrolltrigger timeline to control animation for each section
		const timeline = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				start: 'top bottom',
				end: 'bottom top',
				scrub: 0,
			}
		});

		// Add the y movement value to the timeline
		timeline
			.to(image, {
				yPercent: -25
			});
	}

	parallaxSection.forEach( (section) => {
		// Disable the section parallax if the section has data attribute data-parallax['desktop'] and screen < tablet
		if (section.dataset.parallax === 'desktop' && isSmallMQ.matches) {
			return;
		}

		// Get the section parallax image
		const image = section.querySelector('.js-parallax-image');

		// Play the animation
		parallaxAnimation(section, image);
	});
});
